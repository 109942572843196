footer {
    background: $green-primary;
    min-height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    max-width: 100%;

    @media only screen and (max-width: $desktop) {
        flex-direction: column;
    }
}

.footer {


    &__container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media only screen and (max-width: $desktop-lg) {
            flex-direction: column;
        }

        ul {
            padding: 0;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        &-img {
            width: 16rem;
        }

        @media only screen and (max-width: $desktop-lg) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        list-style: none;
        margin: 0;
        column-gap: 1.5rem;

        li>a>svg {
            width: 1.4rem;
        }
    }

    &__newsletter {
        display: grid;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin: 0;
        color: $white;

        @media only screen and (max-width: $desktop-lg) {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }

    &-cp {
        position: absolute;
        left: 50%;
        bottom: -2rem;
        transform: translateX(-50%);
        color: $white;
        font-family: 'inter-light', 'inter', Verdana, sans-serif;
        font-size: 0.5rem;
        margin: 0;

        @media only screen and (max-width: $desktop) {
            bottom: 0.5rem;
        }
    }
}

.newsletter-modal {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    z-index: 9999;
    }

.newsletter-modal-content {
    background-color: $white;
    color: $green-primary;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: $desktop) {
        width: 100%;
    }

    @media only screen and (min-width: $desktop) {
        width: 50%;
    }
}