.about {
    &__heading {
        width: 100%;
        height: 50vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__dsc {
        padding-top: 5rem;
        padding-bottom: 5rem;
        color: $green-primary;

        &-title {
            font-size: 3.5rem;
        }
    }
}