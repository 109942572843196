.product-recensions {
    background: #fff;
  
    &__container {
      padding-bottom: 1.5rem;
      h4 {
          text-transform: uppercase;
          font-size: 1.2rem;
      }
    }
  
    .p-recensions-title {
      display: flex;
      padding: 1.5rem 0;
      text-transform: uppercase;
  
      h3 {
          min-width: fit-content;
          margin: 0;
      }
    }
  
    .r-line {
      position: relative;
      width: 100%;
      margin-left: 1rem;
  
      ::after {
          position: absolute;
          right: 0;
          top: 50%;
          content: '';
          background: #000;
          width: 100%;
          height: 2px;
          transform: translateY(-50%);
      }
    }
  
    .recension {
      display: flex;
      border: 1px solid $green-primary;
      margin-bottom: 1rem;
  
      
    @media only screen and (max-width: $desktop) {
      flex-direction: column;
    }
  
    }
  
  
    .recension__avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
  
      @media only screen and (max-width: $desktop) {
        flex-direction: row;
      }
  
      @media only screen and (min-width: $desktop) {
        flex-direction: column;
      }
  
      &-ico {
          text-align: center;
          svg {
              width: 5rem;
          }
      }
      &-info {
          text-align: center;
  
          p {
              margin-bottom: 0.2rem;
          }
  
          p:nth-child(1) {
              white-space: nowrap;
          }
  
          p:nth-child(2) {
              color: $green-primary;
          }
  
          p:nth-child(3) {
              font-size: 0.7rem;
          }
      }
    }
  
    .recension__dsc {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 1rem;
  
      @media only screen and (max-width: $desktop) {
        padding-top: 0;
      }
  
      &-rating {
          color: $green-primary;
          
          .recension-star {
              font-size: 2rem;
          }
      }
  
      &-body {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
  
          p {
              margin: 0;
          }
      }
    }
  }

  .r-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    padding-bottom: 4rem;
    background: $green-primary;
    color: $white;

    h3 {
        text-transform: uppercase;
    }
  }

  .recension-form {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $desktop) {
      width: 100%;
    }

    @media only screen and (min-width: $desktop) {
      min-width: 300px;
      max-width: 50%;
    }


    &-name, &-email, &-textarea {
        padding: 0.3rem 1rem;
        background: $green-primary;
        color: $white;
        border: none;
        border-bottom: 1px solid $white;
        border-radius: 8px;
        white-space: normal;
        box-shadow: inset 10px 10px 20px #022118,
        inset -10px -10px 20px #043d2c;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 6px 1px $white;
        }
    }

    button {
      padding: 0.5rem;
    }
    
    .stars {
        position: relative;

        .stars-container{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
          }
          
          .stars-container .container__items{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            gap: 0 .5em;
            height: 100%;
            
            @media only screen and (max-width: $desktop) {
                width: 60%;
            }
        
            @media only screen and (min-width: $desktop) {
                min-width: 300px;
                max-width: 50%;
            }
          }
          
          .stars-container .container__items input{
            display: none;
          }
          
          .stars-container .container__items label{
            width: 20%;
            aspect-ratio: 1;
            cursor: pointer;
          }
          
          .stars-container .container__items label .star-stroke{
            display: grid;
            place-items: center;
            width: 100%;
            height: 100%;
            background: $white;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          }
          
          .stars-container .container__items label .star-stroke .star-fill{
            width: 70%;
            aspect-ratio: 1;
            background: $green-primary;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          }
          
          .stars-container .container__items input:hover ~ label .star-stroke,.container .container__items input:checked ~ label .star-stroke{
            background: $text-link;
          }
          
          .stars-container .container__items input:checked ~ label .star-stroke .star-fill{
            background: $white;
          }
    }
  }

  .recension-form-name:focus, .recension-form-email:focus, .recension-form-textarea:focus {
    box-shadow: 0 0 10px $white;
    border: 1px solid $white;
    outline: $white;
  }