.products-list {

  padding-bottom: 5rem;

  &__categories {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green-primary;
    color: $white;
    padding-top: 5.5rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;

    @media only screen  and (max-width: $desktop) {
      flex-wrap: wrap;
    }

  li {
      padding-top: 1rem;
    
  }

  a {
    color: $white;
  }

  a:hover {
    color: $green-link;
    border-bottom: 1px solid $green-link;
  }

    &-item {
      margin: 1rem 2rem;
      color: $green-primary;
      text-transform: uppercase;
      transition: color 0.5s, border-color 0.5s;
      border-bottom: 1px solid transparent;

      @media only screen  and (max-width: $desktop) {
        margin: 2rem;
      }

      &--selected {
        color: $green-link;
        border-bottom: 1px solid $green-link;
      }
    }
  }
}

.products {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.product__container {
  display: grid;
  position: relative;
  background: #fff;
  border: 1px solid $green-primary;
  transition: box-shadow 0.3s;

  @media only screen and (max-width: 768px) {
      grid-column: span 12;

      p {
          font-size: 1rem;
      }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
      grid-column: span 6;

      p {
          font-size: 1rem;
      }
  }

  @media only screen and (min-width: 1024px) {
      grid-column: span 4;

      p {
          font-size: 1rem;
      }
  }

  &:hover img{
      transform: scale(1.05) translateY(-50%);
  }

  &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  figure {
    margin: 0;
  }

  .p-info {
    align-self: end;
    color: $green-primary;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title p{
      font-family: 'inter-bold', 'inter', Verdana, Tahoma, sans-serif;
      font-size: 1.5rem;
    }

    &__price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;

      .sale-price {
        position: absolute;
        top: -14px;
        color: red;
        font-size: 0.8rem;
        text-decoration: line-through;
        vertical-align: super;
      }
    }
  }
}

.product-favorits {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 99;
  cursor: pointer;

  &__form {

    button {
      background: none;
      border: none;
    }

    svg {
      width: 1.5rem;
      stroke: $green-primary;
      transition: all 0.5s;
    }
  
    svg path {
      fill: $white;
      transition: all 0.5s;
    }
  
    &:hover {
      svg {
        animation: pulsing 1s infinite ease-in-out;
      }
    }
  
    &.active {
      svg path{
        fill: $green-primary;
      }
    }
  }
}

@keyframes pulsing {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.2;
  }

  100% {
    scale: 1;
  }
}

.image__container {
  width: 100%;
  max-width: 700px;
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.image__container img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  padding: 1rem;
  transition: transform 0.5s;
}

.feedback {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  margin-right: 5px;
  opacity: 0;
  z-index: 100;
  transition: all 0.5s ease-in-out;
}

.feedback.show {
  animation: cartAdd 1.5s;
}

@keyframes cartAdd {
  0% {
    opacity: 0;
    bottom: 2rem;
  }
  50% {
    opacity: 1;
    bottom: 3.5rem;
  }
  100% {
    opacity: 0;
    bottom: 6rem;
  }
}

// ==============================================================================================

.bg-light {
	background-color: #fff !important;
    transition: all 200ms linear;
}
.section {
    position: relative;
	width: 100%;
	display: block;
}

.over-hide {
    overflow: hidden;
}

.nav-item .dropdown-menu {
    transform: translate3d(0, 10px, 0);
    visibility: hidden;
    opacity: 0;
	max-height: 0;
    display: block;
	padding: 0;
	margin: 0;
    transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
    opacity: 1;
    visibility: visible;
	max-height: 999px;
    transform: translate3d(0, 0px, 0);
}
.dropdown-menu {
	padding: 10px!important;
	margin: 0;
	font-size: 13px;
	letter-spacing: 1px;
	color: black;
	background-color: white;
	border: none;
	border-radius: 3px;
	box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
    transition: all 200ms linear;
}
.dropdown-toggle::after {
	display: none;
}

.dropdown-item {
	padding: 3px 15px;
	color: #212121;
	border-radius: 2px;
    transition: all 200ms linear;
}
.dropdown-item:hover,
.dropdown-item:focus {
	color: #fff;
	background-color: rgba(82,96,76,.6);
}

@media (max-width: 767px) {
	.nav-item:after{
		display: none;
	}

	.dropdown-menu {
		padding: 0 !important;
		color: black !important;
		background-color: white;
		box-shadow: none;
		transition: all 200ms linear;
	}

	.dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
		margin-top: 10px !important;
		margin-bottom: 20px !important;
	}
}

.cat-card{
    margin: 20px 10px 10px 10px;
}

.shop-list-card{
    width: 300px;
    height: 400px;
     box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
}
.card-img-top{
    display: block;
    margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
    width: 200px;
    height: 260px;
}

.dd-selected{
    background: rgb(82,96,76,0.5);
}

.dropdown {
    display: inline-block;
    position: relative;
    }
    
    .dd-button {
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    background-color: #52604C;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 30px 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    }
    
    .dd-button:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    }
    
    .dd-button:hover {
    background-color: rgb(82, 96, 76, 0.6)
    }
    
    
    .dd-input {
    display: none;
    }
    
    .dd-menu {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 15px 0 15px;
    margin: 0 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
    background-color: #ffffff;
    list-style-type: none;
    z-index: 999;
    }
    .dd-menu a{
      font-size: 14px;
      padding: 0px 15px 0px 15px;
    }
    
    .dd-input + .dd-menu {
    display: none;
    }
    
    .dd-input:checked + .dd-menu {
    display: block;
    }
    
    .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    }
    
    .dd-menu li:hover {
    background-color: #f6f6f6;
    }
    
    .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
    }
    
    .dd-menu li.divider{
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #cccccc;
    }

    /* ====================  SHOP CATEGORY CARDS ============================================== */


.cat-card{
    margin: 20px 10px 10px 10px;
  }
  @media only screen and (min-width: 768px) {
  .vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  }
  }
  
  /* ====================  SHOP LIST ITEMS ============================================== */
  
  @media only screen and (min-width: 768px) {
  .shop-list-card{
    width: 220px;
    height: 320px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
    transition: all ease-in 0.3s;
  }
  .shop-list-card:hover  a{
  font-weight: 700;
  }
  .shop-list-card:hover .card-img-top{
  scale: 0.9;
  }
  .card-body a p{
  transition: all ease-in 0.3s;
  }
  .card-img-top{
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 215px;
    height: 240px;
    transition: all ease-in 0.3s;
  }
  .title-size{
    font-size: 1em;
  }
  }
  @media only screen and (max-width: 768px) {
  .shop-list-card{
    width: 150px;
    height: 200px;
     box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  }
  .card-img-top{
    display: block;
    margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
    width: 120px;
    height: 130px;
  }
  .title-size{
    font-size: 0.7em;
  }
  }

  .card a {
    color: $green-primary;
  }

  @media only screen and (max-width: 768px) {
    .blog-dtl-img{
      float: none;
      animation: fadeInLeft .8s ease-in-out;
    }
    .blog-dtl-txt{
      float: none;
      animation: fadeInRight .8s ease-in-out;
    }
    }
    @media only screen and (min-width: 768px) {
    .blog-dtl-img{
      float: left;
      width: 50%;
      animation: fadeInLeft .8s ease-in-out;
    }
    .blog-dtl-txt{
      animation: fadeInRight .8s ease-in-out;
      float: right;
      width: 50%;
    }
}