
@font-face {
    font-family: 'inter';
    src: url('../fonts/Inter-Regular.ttf') format('truetype'), 
  }

  @font-face {
    font-family: 'inter-light';
    src: url('../fonts/Inter-Light.ttf') format('truetype'), 
  }

  @font-face {
    font-family: 'inter-bold';
    src: url('../fonts/Inter-Bold.ttf') format('truetype'), 
  }

  @font-face {
    font-family: 'bebas-neue';
    src: url('../fonts/BebasNeue-Regular.ttf') format('truetype'), 
  }