.cart-details {
    min-height: 90vh;
}

#coupon-form {
    margin-top: auto;
    margin-bottom: auto;
    @media only screen and (max-width: 768px) {
        margin-bottom: 2rem;
    }
}