.order-created {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    height: 90vh;

    &__container {
        min-height: 90vh;
    }
}