.order-create {
    padding-top: 7rem;
    padding-bottom: 4rem;

    &__container {
        background: $green-primary;
        color: $white;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 2rem 1rem;

        @media only screen and (max-width: $desktop) {
            flex-direction: column;
        }
    }

    &__details {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #orderCreateForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        input::placeholder {
            color: $text-link;
            padding: 0.5rem 1rem;
        }
    }

    .orderCreate-field {
        width: 100%;
        background: $green-primary;
        color: $white;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 6px 1px $white;
        }

        &--input {
            border: none;
            border-bottom: 1px solid $white;
            border-radius: 8px;
            margin-bottom: 1rem;
            box-shadow: inset 10px 10px 20px #022118,
            inset -10px -10px 20px #043d2c;
            padding: 0.5rem 1rem;
        }
    }

    .orderCreate-confirm {
        display: grid;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    #id_receive_newsletter, #payway, #terms {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        padding: 0.3rem 0.5rem;
    }
}