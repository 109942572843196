.contact {
    overflow: hidden;
    background-image: url("../../vendors/images/contact-bg.jpg");
    background-size: cover;

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding-top: 7rem;
        padding-bottom: 5rem;
    }
}

.contact__box {
    background: $green-primary;
    color: $white;
    max-width: 100%;

    &-left__form {
        display: flex;
        flex-direction: column;

        .form {
            &-name, &-email, &-textarea {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        input, textarea {
            padding: 0.3rem 1rem;
            background: $green-primary;
            color: $white;
            border: none;
            border-bottom: 1px solid $white;
            border-radius: 8px;
            white-space: normal;
            box-shadow: inset 10px 10px 20px #022118,
            inset -10px -10px 20px #043d2c;
            padding: 0.5rem 1rem;

            &:focus-visible {
                outline: none;
                box-shadow: 0 0 6px 1px $white;
            }
        }

        input::placeholder, textarea::placeholder {
            color: $text-link;
        }

        .btn-white-link {
            background: $green-primary;

            svg {
                width: 79.698px; 
                height: 31.937px;
                padding-left: 1rem;
    
                g {
                    stroke: $white;
                    stroke-width: 2;
                }
            }
    
            &:hover {
                box-shadow: 0 0 6px 1px $white;
            }
        }
    }

    &-right {

        a svg {
            width: 1rem;
        }

        &__newsletter-form-fields {
            display: flex;
            align-items: baseline;

            p {
                @media only screen and (max-width: 768px) {
                    max-width: 70%;
                }
            }
        }

        &__newsletter-form-fields p input{
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .single-form-btn {
            background: $green-primary;
            color: $white;
            border: 1px solid $white;
            border-radius: 0 30px 30px 0;
            padding: 0.5rem;
            padding-right: 1rem;
        }
    }
}
