.landing {
    min-height: 70vh;
    overflow: hidden;
}

//heading
.heading-container {
    position: relative;
    height: 100vh;
    max-width: 100%;

    @media only screen and (max-width: $desktop) {
      height: 70vh;
    }
}

.h-image {
width: 100%;
height: 100%;
position: absolute;
inset: 0;
transition: opacity 1s ease-in-out;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.h-image__dsc-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -20%;
    display: flex;
    align-items: center;
    z-index: 1;

    @media only screen and (max-width: $desktop) {
        position: absolute;
        inset: 0;
        left: unset;
    }

    @media only screen and (min-width: $desktop) {
        width: 50%;
    }
}

.h-image__dsc {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: start;
    width: fit-content;
    top: 5rem;
    max-width: 70%;
    z-index: 1;
    color: #fff;

    @media only screen and (max-width: $desktop) {
        max-width: 100%;
    }

    &-suptitle h3 {
        font-size: 1.4rem;
        font-family: 'inter-light', 'inter', Verdana, sans-serif;
        white-space: nowrap;
        margin: 0;

        @media only screen and (max-width: $desktop) {
            display: none;
        }
    }

    &-title {
        white-space: nowrap;
        h1 {
            text-transform: uppercase;

            @media only screen and (min-width: $desktop) {
                font-size: 6rem;
                line-height: 1.22;
                margin: 0;
            }
        }
    }

    &-text {
        p {
            line-height: 1.72;
            font-family: 'inter-light', 'inter', Verdana, sans-serif;

            @media only screen and (max-width: $desktop) {
                max-width: 100%;
            }
        
            @media only screen and (min-width: $desktop) {
                max-width: 70%;
            }
        }
    }

    &-btn {
        margin-top: 4.5rem;
        width: fit-content;
        border: 2px solid #fff;
        border-radius: 60px;
        transition: all 0.5s;

        @media only screen and (max-width: $desktop) {
            margin-top: 0;
        }

        svg {
            width: 79.698px; 
            height: 31.937px;
            padding-left: 1rem;

            g {
                stroke: #fff;
                stroke-width: 2;
            }
        }

        &:hover {
            box-shadow: 0 0 6px 1px #fff;
        }
    }
}

//categories heading
.categories-container {
    position: relative;
    width: 100%;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        height: 70%;
        background-color: $green-primary;
        width: 100%;
        z-index: -1;
    }
}

.categories-heading {
    display: grid;
    width: 100%;
    padding-top: 5.5rem;
    padding-bottom: 2.5rem;

    @media only screen and (max-width: $desktop) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0.5rem;
        row-gap: 2.5rem;
    }

    @media only screen and (min-width: $desktop) {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 0.8rem;
    }

    &__category {
        position: relative;
        max-width: 100%;
    }

    &__category-name {
        font-family: 'bebas-neue', Verdana, Tahoma, sans-serif;
        position: absolute;
        top: -2.8rem;
        left: 1rem;
        color: #fff;
        z-index: 2;
        font-size: 3.5rem;
        text-transform: uppercase;
    }

    &__category-img {

        img {
            max-width: 100%;
            transition: all 0.5s;
        }

        &:hover img{
            scale: 1.03;
        }
    }
}

//about us
.about-home {
    display: flex;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @media only screen and (max-width: $desktop) {
        flex-direction: column;
    }

    @media only screen and (min-width: $desktop) {
        grid-template-columns: repeat(2, minmax(50%, 1fr));
        column-gap: 3rem;
    }

    &__img {
        margin: 0;

        @media only screen and (max-width: $desktop) {
            width: 100%;
            padding-bottom: 1rem;
        }
    
        @media only screen and (min-width: $desktop) {
            width: 50%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.about-home__dsc {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    @media only screen and (max-width: $desktop) {
        width: 100%;
    }

    @media only screen and (min-width: $desktop) {
        width: 50%;
    }

    &-title {
        h3 {
            color: $green-primary;
            font-size: 4rem;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: 1.5rem;
        }
    }

    &-text {
        color: $green-primary;
        padding-bottom: 2rem;
    }

    &-btn {
        padding-bottom: 2rem;

        .btn-green-link {
            transition: all 0.5s;

            &:hover {
                box-shadow: 0 0 6px 1px $green-primary;
            }
        }

        svg {
            width: 77.75px;
            height: 30px;
            padding-left: 1rem;
        }
    }
}

//images
.home-images {
    width: 100%;
}

.home-images__image {
    position: relative;

    &-img {

        img {
            width: 100%;
        }
    }

    &-title {
        position: absolute;
        display: flex;
        width: 100%;
        inset: 0;
        justify-content: center;
        align-items: center;
        z-index: 2;

        h3 {
            color: #fff;
            font-size: 4rem;
            text-transform: uppercase;
            text-align: center;
            margin: 0;

            @media only screen and (max-width: $desktop) {
                font-size: 2rem;
            }
        }
    }
}

// products slider - multi carousel 
.products-slider {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 60vh;
    padding-top: 5rem;
    padding-bottom: 3rem;
    overflow: hidden;

    .swiper-wrapper {
        display: flex;
        flex-direction: row;
        align-items: end;
        height: 100%;
    }

    &__slide {
        display: grid;
        height: 100%;
        position: relative;
        background: #fff;
        border: 1px solid $green-primary;
        transition: box-shadow 0.3s;

        @media only screen and (max-width: 768px) {
            grid-column: span 12;
      
            p {
                font-size: 1rem;
            }
        }
      
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            grid-column: span 6;
      
            p {
                font-size: 1rem;
            }
        }
      
        @media only screen and (min-width: 1024px) {
            grid-column: span 4;
      
            p {
                font-size: 1rem;
            }
        }
      
        &:hover {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        }
      
        figure {
          margin: 0;
        }

        &-img {
            width: 100%;
            max-width: 700px;
            aspect-ratio: 1 / 1;
            position: relative;
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;

            img {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
                height: auto;
                object-fit: cover;
                aspect-ratio: 1 / 1;
                padding: 1rem;
                transition: transform 0.5s;
            }
        }

        &__dsc {
            align-self: end;
            justify-self: center;
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
        }

        &__dsc-title {
            padding-bottom: 1rem;

            a {
                font-family: 'inter-bold', 'inter', Verdana, Tahoma, sans-serif;
                color: $green-primary;
                font-size: 1.1rem;
            }
        }

        &__dsc-price {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .sale-price {
                position: absolute;
                top: -14px;
                color: red;
                font-size: 0.8rem;
                text-decoration: line-through;
                vertical-align: super;
              }
        }
    }

    .swiper-button-prev {
        background-image: none;
        position: absolute;
        top: 3rem;
        left: unset;
        right: 8rem;
        width: 4rem; 
        height: 1.6rem;

        svg {
            width: 4rem; 
            height: 1.6rem;
            transform: rotate(180deg);
        }
    }

    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: none;
    }

    .swiper-button-next {
        position: absolute;
        top: 3rem;
        right: 1rem;
        width: 4rem; 
        height: 1.6rem;

        svg {
            width: 4rem; 
            height: 1.6rem;
        }
    }

    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        content: none;
    }

}

//category details
.categories-details__container {
    background-color: $green-primary;
    color: #fff;
}

.categories-details {
    display: grid;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @media only screen and (max-width: $desktop) {
        grid-template-columns: 1fr;
    }

    @media only screen and (min-width: $desktop) {
        grid-template-columns: repeat(2, minmax(50%, 1fr));
    }
}

.categories-details__category {
    padding-top: 2rem;

    &-title {
        text-transform: uppercase;
        padding-bottom: 1rem;

        h3 {
            font-size: 3.5rem;
            margin: 0;
        }
    }

    &-detail {
        display: flex;

        @media only screen and (max-width: $tablet-md) {
            flex-direction: column;
            align-items: center;
        }

        p {
            font-family: 'inter-light', Verdana, Tahoma, sans-serif;
        }
    }

}

.category-detail {

    &__img {
        flex: 1; 
        margin: 0;

        @media only screen and (max-width: $tablet-md) {
            width: 100%;
            padding-bottom: 1rem;
        }

        @media only screen and (min-width: $tablet-md) {
            width: 50%;
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &__dsc {
        flex: 1;
        padding-left: 1rem;
        padding-right: 1rem;

        @media only screen and (max-width: $tablet-md) {
            width: 100%;
            font-size: 1rem;
        }

        @media only screen and (min-width: $tablet-md) {
            width: 50%;
            font-size: 0.8rem;
        }
    }
}

//testemonials 
.testemonials {
    position: relative;
    height: fit-content;
    overflow: visible;
    padding-top: 5rem;
    padding-bottom: 3rem;

    h1 {
        color: $dark-text;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 5rem;
    }

    &__testemonial {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 90%;
        border: 1px solid $green-primary;
        margin-left: 1rem;
        margin-right: 1rem;

        @media only screen and (max-width: $desktop) {
            width: 90%;
            min-width: 80%;
            padding: 1rem 2rem;
        }

        @media only screen and (min-width: $desktop) {
            width: 45%;
            min-width: 45%;
            padding: 1rem 4rem;
        }

        &-quotation-mark {
            position: absolute;
            top: -4.7rem;
            left: 2rem;
            color: $dark-text;
            font-family: 'bebas-neue', 'inter', Verdana, Tahoma, sans-serif;
            font-size: 10rem;
        }

        &-dsc {
            font-size: 0.8rem;
            color: $dark-text;
            padding-bottom: 2rem;
            padding-top: 2rem;
        }

        &-btn {
            padding-bottom: 2rem;
            width: fit-content;

            .btn-green-link {
                transition: all 0.5s;
    
                &:hover {
                    box-shadow: 0 0 6px 1px $green-primary;
                }
            }
    
            svg {
                width: 77.75px;
                height: 30px;
                padding-left: 1rem;
            }
        }
    }

    .swiper-button-prev {
        background-image: none;
        position: absolute;
        top: 11rem;
        left: unset;
        right: 8rem;
        width: 4rem; 
        height: 1.6rem;

        svg {
            width: 4rem; 
            height: 1.6rem;
            transform: rotate(180deg);
        }
    }

    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: none;
    }

    .swiper-button-next {
        position: absolute;
        top: 11rem;
        right: 1rem;
        width: 4rem; 
        height: 1.6rem;

        svg {
            width: 4rem; 
            height: 1.6rem;
        }
    }

    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        content: none;
    }
}

.testemonials__testemonial__person {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;

    &-img {
        width: 5rem;
        height: 5rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &-data {
        color: $dark-text;
        padding-left: 1rem;
        text-transform: uppercase;

        p {
            font-family: 'inter-bold', Verdana, Tahoma, sans-serif;
            font-size: 0.8rem;
            margin: 0;
        }
    }
}
/* Modal Styling */
.testemonial-modals {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;

    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
    &__modal {
      position: relative;
      display: none;
      background-color: #fff;
      padding: 2rem;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

      @media only screen and (max-width: $desktop) {
        max-width: 100%;
      }

      @media only screen and (min-width: $desktop) {
        max-width: 600px;        
      }

      &.active {
        display: block;
      }

      &__person {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1rem;

        &-img {
            padding-right: 1rem;

            @media only screen and (max-width: $desktop) {
                width: 5rem;
            }
        
              @media only screen and (min-width: $desktop) {
                width: 8rem;
            }
        }

        &-img img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        &-name p{
            font-family: 'inter-bold', Verdana, Tahoma, sans-serif;
            margin: 0;
        }
      }
    }
  }
  
  .testemonials__testemonial-btn {
    cursor: pointer;
  }
  
  .close-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    background: #fff;
    padding: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50px;
  }

//news
.news-container {
    background: $green-primary;
    color: #fff;
    padding-top: 3rem;
    padding-bottom: 5rem;

    h2 {
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        padding-bottom: 5rem;
    }
}

.news {
    display: grid;

    @media only screen and (max-width: $desktop) {
        grid-template-columns: 1fr;
    }

    @media only screen and (min-width: $desktop) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
    }

    &__item {
        display: grid;
        padding-bottom: 2rem;
        grid-template-columns: 1fr;
    }

    &__item-title {
        h3 {
            font-family: 'inter-bold', Verdana, Tahoma, sans-serif;
            font-size: 1.3rem;
        }
    }

    &__item-img {
        padding-bottom: 1.5rem;

        img {
            width: 100%;
        }
    }

    &__item-btn {
        justify-self: end;
        width: fit-content;
        border-radius: 60px;
        transition: all 0.5s;

        svg {
            width: 79.698px; 
            height: 31.937px;
            padding-left: 1rem;

            g {
                stroke: #fff;
                stroke-width: 2;
            }
        }

        &:hover {
            box-shadow: 0 0 6px 1px #fff;
        }
    }
}

//instagram feed
.instagram-feed {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h1 {
        color: $green-primary;
    }

    .instagram-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem;
        padding: 0.5rem;
    }

    .instagram-post {
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        width: 100%; /* Ensure the container takes the full width of the grid cell */

        &:before {
            content: '';
            display: block;
            padding-top: 100%; /* 1:1 aspect ratio */
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; /* Ensure the image covers the container */
        }

        p {
            margin: 8px;
            font-size: 0.9em;
            color: $green-primary;
        }
    }

    // Media query for responsiveness
    @media (max-width: $desktop) {
        .instagram-grid {
            gap: 0.2rem;
            padding: 0.2rem;
        }

        .instagram-post {
            p {
                margin: 4px;
                font-size: 0.8em;
            }
        }
    }
}

