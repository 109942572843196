.product-detail {
    padding-top: 6rem;
}

.product {
    display: grid;

    @media only screen and (max-width: $desktop) {
        grid-template-columns: 1fr;
    }

    @media only screen and (min-width: $desktop) {
        grid-template-columns: repeat(2, 1fr);
    }

    &-img {
        display: flex;
        justify-content: center;
    }

    &-details {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media only screen and (max-width: $desktop) {
            padding-bottom: 1.5rem;
        }
    }
}

.product-details-form {
    display: flex;

    label {
        display: none;
    }

    #id_quantity {
        width: 3rem;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: none;
    }
}

.product-details-price {
    position: relative;

    span {
        position: absolute;
        top: -14px;
        color: red;
        font-size: 0.8rem;
        text-decoration: line-through;
        vertical-align: super;
    }
}

.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Set appearance to none for Firefox */
.quantity-input[type="number"] {
  -moz-appearance: textfield;
}

#decreaseNumber, #increaseNumber {
    background: $green-primary;
    color: $white;
    border: 1px solid $green-primary;
    border-radius: 30px 0 0 30px;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover {
        box-shadow: 0 0 6px 1px $green-primary;
      }
}

#increaseNumber {
    border-radius: 0 30px 30px 0;
}

.backLink {
    margin-top: 2rem;
}

.m-product-details-slider {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2rem 0;

    .swiper-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      picture {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          height: 100%;
          width: auto;
          object-fit: cover;
        }
      }
    }

    .swiper-button-prev {
        background-image: none;
        width: 4rem; 
        height: 1.6rem;
        background:rgba($color: #ffffff, $alpha: 0.1);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.1);

        svg {
            width: 4rem; 
            height: 1.6rem;
            transform: rotate(180deg);
        }
    }

    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: none;
    }

    .swiper-button-next {
        width: 4rem;
        height: 1.6rem;
        background:rgba($color: #ffffff, $alpha: 0.1);
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.1);

        svg {
            width: 4rem; 
            height: 1.6rem;
        }
    }

    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        content: none;
    }
  }