nav {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green-primary;
    z-index: 99;
    width: 100%;
    max-width: 100%;
    height: 5.45rem;
    color: #fff;
    overflow: hidden;
    font-family: 'inter-light', 'inter' sans-serif;
    font-weight: 300;
    transition: all 0.5s;

    &.scrolled {
        height: 3.7rem;
    }

    &.open {
        height: 100%;
    }
}

.dist {
    // position: relative;
    // height: 5rem;
    // background: $green-primary;
}

.nav-logo {
    z-index: 101;
    svg {
        width: 16.1306rem; 
        height: 1.975rem;
    }
}

.navigation-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
}

.nav-links {
    display: flex;
    list-style: none;
    margin: 0;

    @media only screen and (max-width: $desktop) {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $green-primary;
        inset: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        z-index: 99;
        transform: translateY(-100%);
        transition: all 1s;

        &.open {
            transform: translateY(0%);
        }
    }

    &__link {
        position: relative;
        padding-right: 2rem;

        @media only screen and (max-width: $desktop) {
            opacity: 0;
            padding-left: 0;
            padding-right: 0;

            &.open {
                animation: obj-scale 0.7s ease-in 1s forwards;
            }
        }

        a svg {
            width: 2rem;

            path {
                stroke: $white;
            }
        }

        .cart {
            &.disabled svg {
                
                path {
                    stroke: $green-link;
                }
            }
        }

        a {
            position: relative;
            color: #fff;
            text-decoration: none;

            @media only screen and (max-width: $desktop) {
                font-family: 'bebas-neue', 'inter', Verdana, Tahoma;
                font-size: 3rem;
            }

            @media only screen and (min-width: $desktop) {
                font-family: 'inter-light', 'inter', Verdana, sans-serif;
                font-size: 1rem;
            }

            &::after {
                position: absolute;
                bottom: -2.4rem;
                left: 0;
                right: 0;
                z-index: 99;
                margin: auto;
                content: '';
                height: 0;
                background: #fff;
                transition: all 0.5s;

                @media only screen and (max-width: $desktop) {
                    display: none;
                }
            }

            &:hover::after{
                height: 1rem;
            }

            &.active::after {
                height: 1rem;
            }

            &.cart {
                position: absolute;
                top: -12px;
                left: -1rem;

                @media only screen and (max-width: $desktop) {
                    left: -2rem;
                }
            }

            &.cart:hover {
                color: $white;
            }
        }

        &.scrolled a{
            &::before {
                position: absolute;
                top: -1.4rem;
                left: 0;
                right: 0;
                z-index: 99;
                margin: auto;
                content: '';
                height: 0;
                background: #fff;
                transition: all 0.5s;

                @media only screen and (max-width: $desktop) {
                    display: none;
                }
            }

            &:hover::before{
                height: 0.7rem;
            }

            &.active::before {
                height: 0.7rem;
            }
        }

        .cart-items {
            position: absolute;
            left: 3.5rem;
            top: 0.8rem;
            margin: 0;

            &:hover {
                color: $white;
            }
        }
    }
}

.menu-btn {
    margin: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;  
    z-index: 99;

    @media only screen and (min-width: $desktop) {
        display: none;
    }
}

.menu-btn__burger {
    width: 2.1875rem;
    height: 3px;
    background: $white;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
    transition: all .5s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 2.1875rem;
    height: 3px;
    background: $white;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
    transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before {
    transform: translateY(-10px);
}

.menu-btn__burger::after {
    transform: translateY(10px);
}

/* ANIMATION */
.menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}

.menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
}

#nav-cart-items {
    @media only screen and (max-width: $desktop) {
        font-size: 2rem;
    }
}