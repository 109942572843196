.blog {
    
    &-title {
        color: $green-primary;
        padding-top: 9rem;
        padding-bottom: 3rem;
        font-size: 4rem;
    }

    .posts {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        padding-bottom: 5rem;
    }
    
    .post__container {
        position: relative;
        background: $green-primary;
    
        @media only screen and (max-width: 768px) {
            grid-column: span 12;
    
            p {
                font-size: 0.8rem;
            }
        }
    
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            grid-column: span 6;
    
            p {
                font-size: 1rem;
            }
        }
    
        @media only screen and (min-width: 1024px) {
            grid-column: span 6;
    
            p {
                font-size: 1rem;
            }
        }
    
        &:hover img{
            transform: scale(1.1);
        }
    }

    .post{

        &__image {
            border-left: 4px solid $green-primary;
            border-top: 4px solid $green-primary;
            border-right: 4px solid $green-primary;
            border-bottom: none;
            overflow: hidden;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.5s;
            }
        }

        &-title {
            display: flex;
            align-items: center;
            background: $green-primary;
            color: $white;
            padding: 0 1rem;
        }
    }
    
}

.blog-post {
    padding-bottom: 5rem;

    &-title {
        color: $green-primary;
        text-align: center;
        padding-top: 9rem;
        padding-bottom: 3rem;
    }

    &__image {
        @media only screen and (max-width: $desktop) {
            display: flex;
            justify-content: center;
        }
        img {

            @media only screen and (max-width: $desktop) {
                padding-bottom: 1rem;
                width: 100%;
            }

            @media only screen and (min-width: $desktop) {
                width: 50%;
                float: left;
                margin: 5px;
                padding-right: 1.5rem;
            }
        }
    }

    &__text {
        text-align: justify; 
    }
}

  