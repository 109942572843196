:root {
    font-family: 'inter', 'bebas-neue', Verdana, Helvetica, sans-serif;
    font-size: 16px;
    font-size: clamp(16px, 1.25vw, 20px);
    text-rendering: geometricPrecision;
    scrollbar-gutter: stable;
    text-underline-position: under;
    @media (-webkit-device-pixel-ratio: 1.5) {
      zoom: 0.66;
    }
    @media (-webkit-device-pixel-ratio: 1.25) {
      zoom: 0.8;
    }
}

body {
  scroll-behavior: smooth;
}

body, p {
  font-family: 'inter', Verdana, Helvetica, sans-serif;
  font-weight: 300;
}

h1, h2, h3 {
  font-family: 'bebas-neue', Verdana, Helvetica, sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
  font-family: 'inter', Verdana, Helvetica, sans-serif;
}

a:hover {
  color: $green-link;
}

.no-scroll {
  overflow: hidden;
}

.overlay-link {
    position: absolute;
    inset: 0;
    z-index: 2;
}

//Buttons
.btn-white-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: $white;
  border: 1px solid $white;
  border-radius: 60px;
  padding: 0.8rem 1.8rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.5s;

  @media only screen and (max-width: 768px) {
    display: inline-block;
  }

  &:hover {
    box-shadow: 0 0 6px 1px $white;
    color: $white;
  }

  &:hover svg {
    animation: btn-hover 1.5s linear infinite;
  }

  &:focus:not(.focus-visible) {
    outline: none;
    box-shadow: 0 0 6px 1px $white;

    svg {
      animation: btn-hover 1.5s linear infinite;
    }
  }

  &--order {
    background: $green-primary;
    transition: all 0.5s;
    border: 1px solid $green-primary;

    &:hover {
      box-shadow: 0 0 6px 1px $green-primary;
    }

    &:focus:not(.focus-visible) {
      outline: none;
      box-shadow: 0 0 6px 1px $white;
    }
  }
}

.btn-green-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $green-primary;
  border: 1px solid $green-primary;
  border-radius: 60px;
  padding: 0.8rem 1.8rem;
  text-transform: uppercase;
  text-decoration: none;
  background: none;
  transition: all 0.5s;

  @media only screen and (max-width: 768px) {
    display: inline-block;
  }

  &:hover {
    color: $green-primary;
    box-shadow: 0 0 6px 1px $green-primary;
  }

  &:hover svg {
    animation: btn-hover 1.5s linear infinite;
  }

  &:focus:not(.focus-visible) {
    outline: none;
    box-shadow: 0 0 6px 1px $green-primary;
  }

  &--cart {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  &--addToCart {
    background: $green-primary;
    color: $white;
    margin-left: 1rem;
    margin-right: 1rem;

    &:hover {
      color: $white;
    }
  }
}

.btn-cart {
  position: relative;
  background: $white;
  font-size: 0.8rem;
  padding: 0.5rem 0.8rem;
  z-index: 99;
  transition: all 0.5s;

  &:hover {
    background-color: $green-primary;
    color: $white;
  }
}

.single-form-btn {
  background: $green-primary;
  color: $white;
  border: 1px solid $white;
  border-radius: 0 30px 30px 0;
  padding: 0.5rem;
  padding-right: 1rem;
  transition: all 0.5s;

  &:hover {
    box-shadow: 0 0 6px 1px $white;
  }

  &:focus:not(.focus-visible) {
    outline: none;
    box-shadow: 0 0 6px 1px $white;
  }

  &--coupon {
    border: 2px solid $green-primary;

    &:hover {
      box-shadow: 0 0 6px 1px $green-primary;
    }
  }

}

.text-link {
  color: $text-link;
  transition: all 0.5s;

  &:hover {
    text-decoration: underline;
  }
}

#newsletter-form .input-group input {
  border: 1px solid $white;

  @media only screen and (max-width: 768px) {
    max-width: 200px;
  }
}

//Div animation  
.fade-in {
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  
.a-div {
  scale: 0;
  opacity: 0;

  &.show {
    animation: obj-scale 1.5s forwards;
  }
}

.o-div {
  opacity: 0;
  transition: opacity 0.8s;

  &.show {
    animation: fade-in 1s forwards;
  }
}

//Keyframes animatinos
@keyframes obj-scale {
    0% {
      opacity: 0;
      scale: 0.6;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes btn-hover {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(0);
  }
}
