//colors
$light-backgorund: #faf9f4;
$dark-background: #0d0d0d;
$black: #010101;
$white: #ffffff;
$dark-text: #31312F;
$green-primary: #032F22;
$green-secondary: #004031;
$green-heading: #094524;
$green-link: #9da8a5;
$text-link: #929e91;

//break points
$tablet-md: 700px;
$desktop: 992px;
$desktop-lg: 1200px;
