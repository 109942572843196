// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red

// $primary: red;

@import "~bootstrap/scss/bootstrap.scss";

// import Swiper styles
@import 'swiper/css';
@import '../../node_modules/swiper/swiper-bundle.css';

//partials
@import "vars";
@import "global";
@import "fonts";
@import "nav";
@import "landing";
@import "footer";
@import "about";
@import "blog";
@import "contact";
@import "shop";
@import "pagination";
@import "cart";
@import "product-detail";
@import "order-create";
@import "order-created";
@import "recensions";

.jumbotron {
  // should be relative path of the entry scss file
  background-image: url("../../vendors/images/sample.jpg");
  background-size: cover;
}
